import {IdToken, User} from '@auth0/auth0-spa-js';
import {IdleSessionTimeout} from 'idle-session-timeout';
import {firstValueFrom, Observable, ReplaySubject} from 'rxjs';
import {DeferredAuth0Client} from './DeferredAuth0Client';
import {AuthUnaryInterceptor} from './interceptors';

export enum AuthStorageKeys {
  RETURN_ROUTE = 'phafReturnRoute',
  LOGOUT_RETURN_TO = 'phafLogoutReturnTo',
}

export enum LoginStatus {
  LoggedIn,
  LoggedOut,
}

export enum CIAMPermissionDeniedTypes {
  // PermissionDenied from CIAM BE, but unrecognized by MFE
  PermissionDeniedDefault = 1,
  // User is unauthorized
  PermissionDeniedUnauthorized,
  // The login user's FHIR person doesn't have the actor reference
  PermissionDeniedActorPersonMismatch,
  // The login user's FHIR person not found
  PermissionDeniedNoFHIRPerson,
  // FHIR resource for permission check not found
  PermissionDeniedNoResource,
}

export type PermissionDeniedCallback = (
  permissionDeniedType: CIAMPermissionDeniedTypes,
  errorMsg: string
) => void;

export interface AuthConfig {
  domain: string;
  clientId: string;
  redirectUri: string;
  audience: string;
  // Optional usingReactAuthMFE flag, default to false if undefined
  // the value of this flag determines whether we use the react-auth-mfes
  // pages for redirect and logout
  usingReactAuthMFE: boolean;
  // Optional scope, default is 'openid profile email ciam' if undefined
  scope?: string;
  // Optional enableAutoLogout, default to true in setup client if undefined
  enableAutoLogout?: boolean;
  // Optional appName, we would want to enforce after all users switch to dynamic auth0 config
  appName?: string;
  // Optional permissionDeniedCallBack. If specified, it will be evoked when auth mfe
  // sees a ciam permission denied error
  permissionDeniedCallBack?: PermissionDeniedCallback;
}

const DEFAULT_IDLE_TIMEOUT_MS = 15 * 60 * 1000; // 15 min
const TWO_MIN_MS = 2 * 60 * 1000; // 2 min

class AuthStorage {
  /**
   * For storing a key value pair into local storage for Auth
   * @param key
   * @param value
   */
  storeAuthItem(key: string, value: string) {
    localStorage.setItem(key, value);
  }
  /**
   * For removing a key value pair from local storage for Auth
   * also returns the value stored
   * @param key
   * @param value
   */
  clearAuthItem(key: string): string {
    const oldValue = localStorage.getItem(key);
    if (!oldValue)
      throw new Error(`No value stored for ${key} in localStorage`);
    localStorage.removeItem(key);
    return oldValue;
  }
}

class Auth {
  // Default to false because only users call setUp()
  // will potentially user react-auth-mfe now
  private usingReactAuthMFE: Boolean = false;
  private idleSessionTimeout: IdleSessionTimeout | null = null;
  private client: DeferredAuth0Client = new DeferredAuth0Client();
  private unaryInterceptor$: ReplaySubject<AuthUnaryInterceptor> =
    new ReplaySubject<AuthUnaryInterceptor>(1);
  private storage: AuthStorage = new AuthStorage();
  private loginStatusSubject = new ReplaySubject<LoginStatus>(1);
  readonly loginStatus$: Observable<LoginStatus> =
    this.loginStatusSubject.asObservable();

  private resolveHasLogInFlowCompleted: undefined | ((value: unknown) => void);
  private hasLogInFlowCompleted = new Promise(resolve => {
    this.resolveHasLogInFlowCompleted = resolve;
  });

  private handleRedirectCallback = () => {
    // Does nothing if not a login redirect.
    this.handleAuthReady(this.client);
  };

  handleRedirectFromReactAuth = () => {
    if (this.usingReactAuthMFE) {
      this.handleAuthReady(this.client);
    }
  };

  // Temporary workaround to allow tests to remove this callback.
  // See go/auth-mfe-quirks.
  readonly TEST_ONLY_CACHED_WINDOW_LOAD = this.handleRedirectCallback;

  /**
   * A wrapper function for wrapping up the setUpClient and take AuthConfig as param
   * we define it for the future migration from setUpClient to setUByAppName
   * @param config AuthConfig that contains params to register new Auth0 client
   */
  async setUp(config: AuthConfig) {
    // we save the value here.
    this.usingReactAuthMFE = config.usingReactAuthMFE;
    await this.setUpClient(
      config.domain,
      config.clientId,
      config.redirectUri,
      config.audience,
      config.scope,
      config.enableAutoLogout,
      config.permissionDeniedCallBack
    );
  }

  /**
   * Registers a new Auth0Client to be used by interceptors and logout.
   * Don't call this and setUpAppName() on the same Auth instance
   * @param domain Auth0 domain (e.g. phaf.auth0.com)
   * @param clientId Auth0 client id (e.g. 1234567890abcdef)
   * @param redirectUri Auth0 redirect uri (e.g. https://phaf.io/login-redirect)
   * @param audience Auth0 audience (e.g. https://api.phaf.io)
   * @param scope Auth0 scope (e.g. 'openid profile email ciam' as default)
   */
  async setUpClient(
    domain: string,
    clientId: string,
    redirectUri: string,
    audience: string,
    scope = 'openid profile email ciam',
    enableAutoLogout: boolean = true,
    permissionDeniedCallBack: undefined | PermissionDeniedCallback = undefined
  ) {
    redirectUri = this.usingReactAuthMFE
      ? `${window.location.origin}/auth/callback`
      : redirectUri;
    await this.client.init(domain, clientId, {
      redirect_uri: redirectUri,
      scope,
      audience,
    });
    this.unaryInterceptor$.next(
      new AuthUnaryInterceptor(
        this.client,
        {domain, appName: null},
        this.storage,
        permissionDeniedCallBack
      )
    );

    if (await this.client.isAuthenticated()) {
      this.loginStatusSubject.next(LoginStatus.LoggedIn);
    } else {
      this.loginStatusSubject.next(LoginStatus.LoggedOut);
    }

    if (enableAutoLogout) {
      this.enableIdleSessionTimeout(
        () => {
          this.logout();
        },
        () => {},
        DEFAULT_IDLE_TIMEOUT_MS
      );
    }

    if (!this.usingReactAuthMFE) {
      // We may have the queryParams available and miss the load event.
      // In this case we want to fire the callback still to continue the auth flow.
      if (document.readyState === 'complete') {
        this.handleRedirectCallback();
      } else {
        // No need to have listener if we have complete auth flow. This function
        // Would re-run on fresh load of application.
        window.addEventListener('load', this.handleRedirectCallback);
      }
    }
  }

  /**
   * Provide an app name for CIAM to lazy load the auth0 config
   * Don't call this and setUpClient() on the same Auth instance
   * @param appName
   * @param redirectUri
   */
  async setUpByAppName(
    appName: string,
    redirectUri: string,
    scope = 'openid profile email ciam',
    enableAutoLogout: boolean = true,
    permissionDeniedCallBack: undefined | PermissionDeniedCallback = undefined
  ) {
    redirectUri = this.usingReactAuthMFE
      ? `${window.location.origin}/auth/callback`
      : redirectUri;
    this.client.updateAuthorizationParams({redirect_uri: redirectUri, scope});
    this.unaryInterceptor$.next(
      new AuthUnaryInterceptor(
        this.client,
        {domain: null, appName},
        this.storage,
        permissionDeniedCallBack
      )
    );
    if (enableAutoLogout) {
      this.enableIdleSessionTimeout(
        () => {
          this.logout();
        },
        () => {},
        DEFAULT_IDLE_TIMEOUT_MS
      );
    }
    window.addEventListener('load', this.handleRedirectCallback);
  }

  /**
   * Enables inactivity timeouts. Any cursor/keyboard/scroll event counts as activity
   * @param onTimeout what to do after timeout. Simplest implementation is `() => authInstance.logout()`
   * @param timeoutMs timeout duration, default is 15 min
   */
  async enableIdleSessionTimeout(
    onTimeout: () => void,
    onTwoMinuteLeft: () => void,
    timeoutMs?: number
  ) {
    this.idleSessionTimeout = new IdleSessionTimeout(
      timeoutMs ?? DEFAULT_IDLE_TIMEOUT_MS
    );
    this.idleSessionTimeout.onTimeOut = onTimeout;
    let hasTwoMinuteChanged: Boolean = false;
    this.idleSessionTimeout.onTimeLeftChange = timeLeft => {
      if (timeLeft < TWO_MIN_MS && !hasTwoMinuteChanged) {
        onTwoMinuteLeft();
        hasTwoMinuteChanged = true;
      } else if (timeLeft > TWO_MIN_MS) {
        hasTwoMinuteChanged = false;
      }
    };
    this.idleSessionTimeout.start();

    if (await this.client.isAuthenticated()) {
      this.idleSessionTimeout.start();
    }
  }

  private isInAuthFlow(): boolean {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.has('code') && queryParams.has('state');
  }

  /**
   * Returns GRPC Unary Interceptor
   */
  async getInterceptor(): Promise<AuthUnaryInterceptor> {
    if (this.isInAuthFlow()) {
      await this.hasLogInFlowCompleted;
    }
    return firstValueFrom(this.unaryInterceptor$);
  }

  /**
   * Returns ID token claims from the underlying auth0 client
   */
  getIdTokenClaims(): Promise<IdToken | undefined> {
    return this.client.getIdTokenClaims();
  }

  /**
   * Called when the page `load`s or if it is already in a ready state.
   * If this is after a redirect from auth0 (which has code and state query parameters),
   * parses them out. See https://auth0.com/docs/quickstart/spa/vanillajs/interactive#handle-the-callback-from-auth0.
   */
  private async handleAuthReady(client: DeferredAuth0Client): Promise<void> {
    if (this.isInAuthFlow()) {
      await client.handleRedirectCallback();
      if (await client.isAuthenticated()) {
        this.loginStatusSubject.next(LoginStatus.LoggedIn);
      }
      // Block RPC calls on this log in flow.
      // After resolving here, `client.handleRedirectCallback` stores the parsed out tokens in local / cookie storage.
      // `this.resume()` can reload the page since these storages are not in volatile memory.
      this.resolveHasLogInFlowCompleted!(true);

      // Logged in now. We want to resend our previously failed request.
      this.resume();
    } else {
      // We weren't in the login flow to begin with.
      this.resolveHasLogInFlowCompleted!(true);
    }
  }

  /**
   * Redirects user to where they were before the login flow.
   */
  private resume() {
    const oldPath = this.storage.clearAuthItem(AuthStorageKeys.RETURN_ROUTE);
    window.location.assign(oldPath);
  }

  /**
   * Performs Auth0 Logout and returns to provided returnTo url.
   */
  async logout(returnTo = window.location.origin): Promise<void> {
    if (this.usingReactAuthMFE) {
      this.storage.storeAuthItem(AuthStorageKeys.LOGOUT_RETURN_TO, returnTo);
      returnTo = `${window.location.origin}/auth/logout`;
    }
    const logoutParams = {
      logoutParams: {
        returnTo,
      },
    };

    return this.client.logout(logoutParams);
  }

  /**
   * This function should only be call by the react auth mfe.
   * It will clear all the items in local storage that is
   * stored by Auth and redirect to a page
   * that user specify when calling logout
   */
  async logoutCompletelyFromReactAuthMFE() {
    let returnTo;
    // Assign origin to returnTo if the key doesn't exist in localstorage
    try {
      returnTo = this.storage.clearAuthItem(AuthStorageKeys.LOGOUT_RETURN_TO);
    } catch {
      returnTo = window.location.origin;
    }
    window.location.assign(returnTo);
  }

  /**
   * Returns promise of the current logged in user.
   */
  async getUser(): Promise<User | undefined> {
    return this.client.getUser();
  }

  /**
   * Returns promise of whether user is logged in
   */
  async isLoggedIn(): Promise<boolean | undefined> {
    return this.client.isAuthenticated();
  }
}

export {Auth, AuthStorage};
